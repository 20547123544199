/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lightgallery@2.7.0/lightgallery.umd.min.js
 * - /npm/lightgallery@2.7.0/plugins/zoom/lg-zoom.umd.js
 * - /npm/lightgallery@2.7.0/plugins/thumbnail/lg-thumbnail.umd.js
 * - /npm/lightgallery@2.7.0/plugins/rotate/lg-rotate.umd.js
 * - /npm/lightgallery@2.7.0/plugins/mediumZoom/lg-medium-zoom.umd.js
 * - /npm/lightgallery@2.7.0/plugins/fullscreen/lg-fullscreen.umd.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
